@font-face {
  font-family: 'insight-icons';
  src: url('../../fonts/insight-icons.eot?nnu5gi');
  src: url('../../fonts/insight-icons.eot?nnu5gi#iefix')
      format('embedded-opentype'),
    url('../../fonts/insight-icons.ttf?nnu5gi') format('truetype'),
    url('../../fonts/insight-icons.woff?nnu5gi') format('woff'),
    url('../../fonts/insight-icons.svg?nnu5gi#insight-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'insight-icons' !important;
  speak: none;
  font-style: normal;
  font-size: 24px;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-logout:before {
  content: '\e928';
}
.icon-deactivate:before {
  content: '\e927';
}
.icon-check-list:before {
  content: '\e926';
}
.icon-copy:before {
  content: '\e925';
}
.icon-messaging:before {
  content: '\e924';
}
.icon-close:before {
  content: '\e922';
}
.icon-close-small:before {
  content: '\e923';
}
.icon-eye-hide:before {
  content: '\e921';
}
.icon-eye-off:before {
  content: '\e920';
}
.icon-eye-on:before {
  content: '\e91f';
}
.icon-warning:before {
  content: '\e91d';
}
.icon-more:before {
  content: '\e91e';
}
.icon-group:before {
  content: '\e91a';
}
.icon-book:before {
  content: '\e91b';
}
.icon-user:before {
  content: '\e91c';
}
.icon-pdf:before {
  content: '\e918';
}
.icon-excel:before {
  content: '\e919';
}
.icon-analytics:before {
  content: '\e917';
}
.icon-widgets:before {
  content: '\e916';
}
.icon-list:before {
  content: '\e915';
}
.icon-refresh:before {
  content: '\e90d';
}
.icon-double-chevron-top:before {
  content: '\e90e';
}
.icon-double-chevron-down:before {
  content: '\e90f';
}
.icon-double-chevron-right:before {
  content: '\e910';
}
.icon-chevron-up:before {
  content: '\e911';
}
.icon-chevron-left:before {
  content: '\e912';
}
.icon-chevron-right:before {
  content: '\e913';
}
.icon-double-chevron-left:before {
  content: '\e914';
}
.icon-map-pin:before {
  content: '\e90b';
}
.icon-plus:before {
  content: '\e90c';
}
.icon-check:before {
  content: '\e90a';
}
.icon-help:before {
  content: '\e904';
}
.icon-export:before {
  content: '\e905';
}
.icon-chevron-down:before {
  content: '\e906';
}
.icon-arrow-left:before {
  content: '\e907';
}
.icon-arrow-right:before {
  content: '\e908';
}
.icon-search:before {
  content: '\e909';
}
.icon-settings:before {
  content: '\e900';
}
.icon-users:before {
  content: '\e901';
}
.icon-money:before {
  content: '\e902';
}
.icon-dashboard:before {
  content: '\e903';
}
