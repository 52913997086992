@import './variables';

h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.bolded-text {
  font-weight: 700;
}

.text-20 {
  font-size: 20px !important;
}

.text-10-medium {
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

.text-10-semibold {
  font-size: 10px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
}

.text-11-light {
  font-size: 11px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.text-11-semibold {
  font-size: 11px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.text-12-medium {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

.text-12-semibold {
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
}

.text-12-bold {
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
}

.text-13-light {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.text-13-semibold {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.text-14-light {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.text-14-medium {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
}

.text-14-semibold {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
}

.text-14-bold {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
}

.text-16-medium {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
}

.text-16-semibold {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.text-18-medium {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

.text-18-semibold {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
}

.text-20-semibold {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
}

.text-24-semibold {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
}

.text-28-semibold {
  font-size: 28px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
}

.icon-text-22 {
  font-size: 22px !important;
}

.text-gray {
  color: $insight-gray;
}

.text-primary {
  color: $insight-primary;
}

.text-orange {
  color: $insight-orange;
}

.text-mild-yellow {
  color: $insight-mild-yellow;
}

.color-white {
  color: white;
}

.color-secondary-dark {
  color: $insight-secondary-dark;
}

.reduce-icon {
  font-size: 16px;
}

.truncate {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  &.two-lines {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  &.shorten {
    max-width: 80%;
  }
}

.break-words {
  word-break: break-all;
}

.break-words-overflow {
  overflow-wrap: break-word;
  hyphens: auto;
}

.break-words-overflow-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.text-uppercase {
  text-transform: uppercase;
}

.marquee {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  span {
    display: inline-block;
    width: auto;
    span {
      @include transition(transform 2s linear);
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:active,
  &:hover {
    span {
      span {
        transform: translateX(-100%) translateX(170px);
      }
    }
  }
}
